<template>
  <div>
    <div v-if="items == null">
      <b-row align-h="center">
        <b-spinner variant="primary" />
      </b-row>
    </div>
    <div v-else>
      <div>
        <b-row
          class="justify-content-end"
          style="font-weight: bold; font-size: 115%"
        >
          {{ $t('budget.total') }} {{ $t('budget.products').toLowerCase() }}: {{ totalProducts }} {{ $t('stocks.units').toLowerCase() }}
        </b-row>

        <b-form
          ref="form"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        >
          <!-- Row Loop -->
          <b-row
            v-for="(item, index) in items"
            :id="item.id"
            :key="item.id"
            ref="row"
          >
            <!-- Mechanic -->
            <b-col md="8">
              <b-form-group
                :label="$t('user.mechanic')"
                label-for="item-name"
              >
                <b-form-select
                  v-model="item.owner_id"
                  placeholder="Manuel"
                  value-field="id"
                  text-field="name"
                  :options="mechanics"
                />
              </b-form-group>
            </b-col>

            <!-- Units -->
            <b-col md="2">
              <b-form-group
                :label="$t('stocks.units')"
                label-for="quantity"
              >
                <b-form-input
                  id="quantity"
                  v-model="item.units"
                  type="number"
                  placeholder="1"
                />
              </b-form-group>
            </b-col>

            <!-- Remove Button -->
            <b-col
              lg="2"
              md="3"
              class="mb-50"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                :disabled="!isAdmin"
                @click="removeItem(index, item.id)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>{{ $t('common.delete') }}</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <b-row
        align-h="between"
        align-v="center"
        class="px-1"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>{{ $t('common.add_new') }}</span>
        </b-button>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormSelect,
  BSpinner,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
// import dbStock from '@/models/stock'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    productId: {
      type: Number,
      default: -1,
    },
    flag: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => [],
    },
    totalProducts: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // items: [],
      payloadRemove: [],
      nextTodoId: 2,
    }
  },
  computed: {
    mechanics() {
      const arr = this.$store.state.app.system?.users_map?.MECHANICS
      const map = new Map()
      arr.forEach(obj => {
        Object.keys(obj).forEach(key => {
          map.set(key, obj[key])
        })
      })

      const array = []
      map.forEach((val, key) => {
        array.push({ id: Number(key), name: val })
      })
      return array
    },
    isAdmin() {
      return JSON.parse(window.localStorage.userData).role === 'ADMIN'
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: null,
        product: this.productId,
        units: 1,
        mechanic: '',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row.length)
      })
    },
    removeItem(index, id) {
      this.payloadRemove.push({ index, id })
      this.items.splice(index, 1)
      this.$emit('remove', this.payloadRemove)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
