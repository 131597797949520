<template>
  <div v-if="items == null">
    <b-row align-h="center">
      <b-spinner variant="primary" />
    </b-row>
  </div>
  <div v-else>
    <div>
      <b-form
        ref="form"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <!-- Product -->
          <b-col md="8">
            <b-form-group
              :label="$t('menu.products')"
              label-for="name"
            >
              <v-select
                v-model="item.name"
                :dir="
                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                "
                :options="products"
                label="name"
                :clearable="false"
                class="mb-2 item-selector-title"
                :placeholder="$t('common.select_item')"
                @input="
                  (val) => {
                    updateItemForm(
                      index,
                      val,
                    );
                  }
                "
              />
            </b-form-group>
          </b-col>

          <!-- Units -->
          <b-col md="2">
            <b-form-group
              :label="$t('stocks.units')"
              label-for="quantity"
            >
              <b-form-input
                id="quantity"
                v-model="item.units"
                type="number"
                placeholder="1"
              />
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col
            lg="2"
            md="3"
            class="mb-50"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              :disabled="!isAdmin"
              @click="removeItem(index, item.id)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>{{ $t('common.delete') }}</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <b-row
      align-h="between"
      align-v="center"
      class="px-1"
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="repeateAgain"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>{{ $t('common.add_new') }}</span>
      </b-button>
    </b-row>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  // BFormSelect,
  BSpinner,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
// import dbProduct from '@/models/product'
import vSelect from 'vue-select'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    // BFormSelect,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    productCode: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      payloadRemove: [],
      nextTodoId: 2,
    }
  },
  computed: {
    products() {
      const obj = this.$store.state.app.system.products.products
      const map = new Map()
      Object.keys(obj).forEach(key => {
        map.set(key, obj[key])
      })
      const array = []
      map.forEach((val, key) => {
        array.push({ id: Number(key), name: val })
      })
      return array
    },
    isAdmin() {
      return JSON.parse(window.localStorage.userData).role === 'ADMIN'
    },
  },
  watch: {
    items() {
      if (this.items !== null) {
        this.items.forEach(i => {
          /* eslint-disable-next-line no-param-reassign */
          i.name = (this.products.find(p => p.id === i.child_product_id))?.name || ''
        })
      }
    },
  },
  mounted() {
    this.initTrHeight()
  },
  async created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    updateItemForm(index, val) {
      // eslint-disable-next-line camelcase
      this.items[index].child_product_id = val.id
    },
    repeateAgain() {
      this.items.push({
        id: null,
        child_product_id: null,
        units: 0,
        name: '',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row.length)
      })
    },
    async removeItem(index, id) {
      // TODO: Add toast
      this.payloadRemove.push({ index, id })
      this.items.splice(index, 1)
      this.$emit('removeDep', this.payloadRemove)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form?.scrollHeight)
      })
    },
    forceUpdateItems() {
      this.items.push({})
      this.items.pop()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
