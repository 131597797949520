import api from '@/api/index'

export default {
  get: async payload => {
    const response = await api.brand.get(payload)
    const { data } = response
    return data
  },
  create: async payload => {
    const response = await api.brand.create(payload)
    return response
  },
  update: async payload => {
    const response = await api.brand.update(payload)
    return response
  },
  delete: async payload => {
    const response = await api.brand.delete(payload)
    return response
  },
}
