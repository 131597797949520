import api from '@/api/index'

export default {
  get: async payload => {
    const response = await api.stock.get(payload)
    const { data } = response
    return data
  },
  create: async payload => {
    const response = await api.stock.set(payload)
    return response
  },
  update: async payload => {
    const response = await api.stock.set(payload)
    return response
  },
  delete: async payload => {
    const response = await api.stock.delete(payload)
    return response
  },
  getStocksByProduct: async payload => {
    const response = await api.stock.getStocksByProduct(payload)
    const { data } = response
    return data
  },
}
