import api from '@/api/index'

export default {
  get: async payload => {
    const response = await api.product.get(payload)
    const { data } = response
    return data
  },
  set: async payload => {
    const response = await api.product.set(payload)
    return response
  },
  delete: async payload => {
    const response = await api.product.delete(payload)
    return response
  },
  getById: async payload => {
    const response = await api.product.getById(payload)
    const { data } = response
    return data
  },
  setDependency: async payload => {
    const response = await api.product.setDependency(payload)
    return response
  },
  getProductDependencies: async payload => {
    const response = await api.product.getProductDependencies(payload)
    const { data } = response
    return data
  },
  deleteProductDependencies: async payload => {
    const response = await api.product.deleteProductDependencies(payload)
    const { data } = response
    return data
  },
  deleteImageProduct: async payload => {
    const response = await api.product.deleteImageProduct(payload)
    const { data } = response
    return data
  },
  getProductMax: async () => {
    const response = await api.product.getProductMax()
    const { data } = response
    return data
  },
  search: async payload => {
    const response = await api.product.search(payload)
    const { data } = response
    return data
  },
}
