import api from '@/api/index'

export default {
  get: async payload => {
    const response = await api.category.get(payload)
    const { data } = response
    return data
  },
  create: async payload => {
    const response = await api.category.create(payload)
    return response
  },
  update: async payload => {
    const response = await api.category.update(payload)
    return response
  },
  delete: async payload => {
    const response = await api.category.delete(payload)
    return response
  },
}
