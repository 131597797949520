<template>
  <div>
    <div v-if="pageLoading">
      <b-row align-h="center">
        <b-spinner variant="primary" />
      </b-row>
    </div>
    <div v-else>
      <b-card-code title="New Product">
        <b-button
          v-if="!isMovil"
          variant="primary"
          style="position:absolute; right:20px; top:20px;z-index:1"
          class="p-0"
          :disabled="loading"
          @click="save"
        >
          <b-overlay
            :show="loading"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
          >
            <b-button
              variant="primary"
              style="position: relative;"
              :disabled="loading"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-25"
              />
              <span>{{ $t("common.save") }}</span>
            </b-button>
          </b-overlay>
        </b-button>
        <div
          v-else
          class="col-12 pt-1"
        >
          <b-button
            variant="primary"
            class="float-right mr-2 zindex-4"
            :disabled="loading"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-25"
            />
            <span>{{ $t("common.save") }}</span>
          </b-button>
        </div>
        <b-tabs
          v-model="tabIndex"
          class="m-2"
          @input="changeTab"
        >
          <b-tab :title="$t('menu.products')">
            <div class="mx-1 mt-1">
              <b-card-body>
                <validation-observer ref="simpleRules">
                  <b-form @submit.prevent>
                    <b-row align-h="between">
                      <b-col
                        md="4"
                        class="border-right"
                      >
                        <!-- name -->
                        <b-form-group
                          :label="$t('new_product.input_fields.name')"
                          label-for="v-name"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Name"
                            rules="required"
                          >
                            <b-form-input
                              v-model="product.name"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('new_product.input_fields.name')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-row>
                          <b-col md="6">
                            <!-- internal Ref -->
                            <b-form-group
                              :label="
                                $t(
                                  'new_product.input_fields.internal_reference'
                                )
                              "
                              label-for="v-internal-ref"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="Internal ref"
                                rules="required|min:6|max:6"
                              >
                                <b-form-input
                                  v-model="product.code"
                                  :state="errors.length > 0 ? false : null"
                                  :placeholder="
                                    $t(
                                      'new_product.input_fields.internal_reference'
                                    )
                                  "
                                  :disabled="false"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <!-- provider ref -->
                            <b-form-group
                              :label="
                                $t(
                                  'new_product.input_fields.provider_reference'
                                )
                              "
                              label-for="v-provider-ref"
                            >
                              <b-form-input
                                v-model="product.provider_reference"
                                :placeholder="
                                  $t(
                                    'new_product.input_fields.provider_reference'
                                  )
                                "
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="3">
                            <!-- active -->
                            <b-form-group
                              :label="$t('new_product.input_fields.active')"
                              label-for="v-active"
                            >
                              <b-form-checkbox
                                v-model="isActive"
                                checked="true"
                                class="custom-control-primary mt-2"
                                switch
                                :disabled="!isAdmin"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col md="9">
                            <!-- Min Stock -->
                            <b-form-group
                              label="Min Stock"
                              label-for="quantity"
                            >
                              <b-form-input
                                id="quantity"
                                v-model="product.min_stock"
                                type="number"
                                placeholder="1"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>

                      <b-col
                        md="4"
                        class="border-right"
                      >
                        <!-- brand -->
                        <b-form-group
                          :label="$t('new_product.input_fields.brand')"
                          label-for="v-brand"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Brand"
                            rules="required"
                          >
                            <b-form-select
                              v-model="product.brand"
                              :state="errors.length > 0 ? false : null"
                              :options="brandOptions"
                              :placeholder="$t('new_product.input_fields.brand')"
                              value-field="code"
                              text-field="name"
                              :disabled="!isAdmin"
                            >
                              <b-form-select-option :value="null">
                                Please select a brand
                              </b-form-select-option>
                            </b-form-select>
                            <small class="text-danger">{{
                              errors[0]
                            }}</small>
                          </validation-provider>
                        </b-form-group>

                        <!-- provider -->
                        <b-form-group
                          :label="$t('new_product.input_fields.provider')"
                          label-for="v-provider"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Provider"
                            rules="required"
                          >
                            <b-form-select
                              v-model="product.provider"
                              :state="errors.length > 0 ? false : null"
                              :options="providerOptions"
                              value-field="code"
                              text-field="name"
                              :disabled="!isAdmin"
                            >
                              <b-form-select-option :value="null">
                                Please select a provider
                              </b-form-select-option>
                            </b-form-select>
                            <small class="text-danger">{{
                              errors[0]
                            }}</small>
                          </validation-provider>
                        </b-form-group>

                        <!-- category -->
                        <b-form-group
                          :label="$t('new_product.input_fields.category')"
                          label-for="v-category"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Category"
                            rules="required"
                          >
                            <b-form-select
                              v-model="product.category"
                              :state="errors.length > 0 ? false : null"
                              :options="categoryOptions"
                              value-field="code"
                              text-field="name"
                              :disabled="!isAdmin"
                            >
                              <b-form-select-option :value="null">
                                Please select a category
                              </b-form-select-option>
                            </b-form-select>
                            <small class="text-danger">{{
                              errors[0]
                            }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col md="4">
                        <!-- buy price -->
                        <b-form-group
                          v-if="isAdmin"
                          :label="$t('new_product.input_fields.buy_price')"
                          label-for="v-buy-price"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Buy price"
                            rules="required"
                          >
                            <b-form-input
                              v-model="product.buy_price"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('new_product.input_fields.buy_price')
                              "
                              type="number"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>

                        <!-- selling price -->
                        <b-form-group
                          :label="$t('new_product.input_fields.selling_price')"
                          label-for="v-selling-price"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Selling price"
                            rules="required"
                          >
                            <b-form-input
                              v-model="product.sell_price"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('new_product.input_fields.selling_price')
                              "
                              type="number"
                              :disabled="!isAdmin"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>

                        <!-- tax rate -->
                        <b-form-group
                          :label="$t('new_product.input_fields.tax_rate')"
                          label-for="v-tax-rate"
                        >
                          <b-form-input
                            v-model="product.tax_rate"
                            :placeholder="
                              $t('new_product.input_fields.tax_rate')
                            "
                            type="number"
                            :disabled="!isAdmin"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <hr>
                    </b-row>
                    <b-row>
                      <!-- description -->
                      <b-col md="6">
                        <b-form-group
                          :label="$t('new_product.input_fields.description')"
                          label-for="v-description"
                        >
                          <b-form-textarea
                            v-model="product.description"
                            :placeholder="
                              $t('new_product.input_fields.description')
                            "
                            rows="3"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- specificaties -->
                      <b-col md="6">
                        <b-form-group
                          :label="$t('new_product.input_fields.specifications')"
                          label-for="v-specificaties"
                        >
                          <b-form-textarea
                            v-model="product.specifications"
                            :placeholder="
                              $t('new_product.input_fields.specifications')
                            "
                            rows="3"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <!-- image & active switch -->
                    <b-row>
                      <b-col md="4">
                        <b-form-group
                          v-if="isAdmin"
                          :label="$t('new_product.input_fields.image')"
                          label-for="v-image"
                        >
                          <b-form-file
                            v-model="image"
                            :placeholder="
                              $t('new_product.input_fields.no_file')
                            "
                            :browse-text="$t('new_product.input_fields.browse')"
                            accept="image/*"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        v-show="image"
                        md="8"
                      >
                        <h6 class="my-2">
                          <b-button
                            v-if="image && isAdmin"
                            variant="danger"
                            class="delete"
                            @click="removeImage()"
                          >
                            <feather-icon
                              icon="TrashIcon"
                              class="mr-25"
                            />
                            <span>{{ $t("common.delete") }}</span>
                          </b-button>
                        </h6>
                        <b-img
                          ref="previewImage"
                          style="max-height: 400px"
                          :src="imageSrc"
                          fluid
                          alt="Product image preview"
                        />
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-card-body>
            </div>
          </b-tab>
          <b-tab
            :title="$t('budget.stock')"
            href="stock"
          >
            <div class="mx-1 mt-1">
              <!-- title & button -->
              <b-card-body>
                <stocks-form
                  :total-products="product.stock"
                  :items="stocks"
                  :product-id="product.id"
                  @remove="remove"
                />
              </b-card-body>
            </div>
          </b-tab>
          <b-tab
            :title="$t('common.dependency')"
            @click="setDependenciesName"
          >
            <div class="mx-1 mt-1">
              <!-- title & button -->
              <b-card-body>
                <tab-dependency
                  ref="tabDependency"
                  :items="dependencies"
                  :product-code="product.product_id"
                  @removeDep="removeDep"
                />
              </b-card-body>
            </div>
          </b-tab>
        </b-tabs>
      </b-card-code>
    </div>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCardBody,
  BFormSelect,
  BFormTextarea,
  BFormFile,
  BFormCheckbox,
  BTabs,
  BTab,
  BImg,
  BSpinner,
  BFormSelectOption,
  BOverlay,
} from 'bootstrap-vue'
import { isMobile } from 'mobile-device-detect'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import BCardCode from '@/views/drs/components/BCardCode.vue'
import dbProduct from '@/models/product'
import dbStock from '@/models/stock'
import dbBrands from '@/models/brand'
import dbCategory from '@/models/category'
import dbProvider from '@/models/provider'
import TabDependency from '@/views/drs/products/components/TabDependency.vue'
import StocksForm from './StocksForm.vue'

export default {
  components: {
    BCardCode,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormSelect,
    BFormTextarea,
    BFormFile,
    BFormCheckbox,
    StocksForm,
    BTabs,
    BTab,
    TabDependency,
    BImg,
    BSpinner,
    BFormSelectOption,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    removePayload: [],
    removePayloadDep: [],
    required,
    tabIndex: 0,
    isMovil: isMobile,
    tabs: ['#stock', '#dependency'],
    product: {
      id: null,
      code: null,
      brand: null,
      provider: null,
      category: null,
    },
    isActive: false,
    stocks: null,
    dependencies: null,
    image: null,
    imageUpdated: false,
    imageHasLoaded: false,
    loading: false,
    pageLoading: false,
    brandOptions: [],
    providerOptions: [],
    categoryOptions: [],
  }),
  computed: {
    mechanics() {
      const arr = this.$store.state.app.system?.users_map?.MECHANICS
      const map = new Map()
      arr.forEach(obj => {
        Object.keys(obj).forEach(key => {
          map.set(key, obj[key])
        })
      })

      const array = []
      map.forEach((val, key) => {
        array.push({ id: Number(key), name: val })
      })
      return array
    },
    imageSrc() {
      if (this.image !== null) {
        return URL.createObjectURL(this.image)
      }
      return ''
    },
    isAdmin() {
      if (!window.localStorage.userData) return false
      return JSON.parse(window.localStorage.userData).role === 'ADMIN'
    },
    isMechanic() {
      if (!window.localStorage.userData) return false
      return JSON.parse(window.localStorage.userData).role === 'MECHANICS'
    },
  },
  watch: {
    image() {
      if (this.imageHasLoaded) this.imageUpdated = true
    },
  },
  mounted() {
    // this.tabIndex = parseInt(window.location.hash.replace('#', ''), 10)
    if (this.$route.query.tabIndex) {
      this.tabIndex = Number(this.$route.query.tabIndex)
    } else {
      this.$router.replace({ query: { page: this.$route.query.page, tabIndex: this.tabIndex } })
    }
  },
  async created() {
    this.pageLoading = true
    this.brandOptions = await dbBrands.get({ paginate: false })
    this.providerOptions = await dbProvider.get({ paginate: false })
    this.categoryOptions = await dbCategory.get({ paginate: false })

    if (this.$route.params.id !== 'new') {
      this.product = await dbProduct.getById({
        productId: this.$route.params.id,
      })

      this.isActive = this.product.active === 1
      if (this.product.images.length) {
        this.image = await this.b64toFile(this.product.images[0])
        this.imageHasLoaded = true
      }

      // Populate dependencies
      this.dependencies = await dbProduct.getProductDependencies(
        this.product.id,
      )

      // Populate stocks
      this.isLoadingStock = true
      this.stocks = await dbStock.getStocksByProduct(this.product.id)
      this.isLoadingStock = false
    } else {
      this.dependencies = []
      this.stocks = []

      // get and format code
      this.product.code = Number((await dbProduct.getProductMax())) + 1
      this.product.code = this.product.code.toString()

      while (this.product.code.length < 6) {
        this.product.code = '0'.concat(this.product.code)
      }
    }
    this.imageUpdated = false
    this.pageLoading = false
  },
  methods: {
    changeTab() {
      // Save the current tab in url
      // window.location.hash = this.tabIndex
      this.$router.replace({ query: { page: this.$route.query.page, tabIndex: this.tabIndex } })
    },
    async remove(payload) {
      this.removePayload = payload
    },
    async removeDep(payload) {
      this.removePayloadDep = payload
    },
    removeItem() {
      try {
        this.removePayload.forEach(async item => {
          if (item.id != null) await dbStock.delete(item.id)
          this.stocks.splice(item.index, 1)
          this.trTrimHeight(this.$refs.row.length)
        })
      } catch (error) {
        /* eslint-disable-next-line no-console */
        console.error(error)
      }
    },
    removeItemDep() {
      try {
        this.removePayloadDep.forEach(async item => {
          if (item.id != null) await dbProduct.deleteProductDependencies(item.id)
          this.dependencies.splice(item.index, 1)
          this.trTrimHeight(this.$refs.row.length)
        })
      } catch (error) {
        /* eslint-disable-next-line no-console */
        console.error(error)
      }
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    save(delImg = false) {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            this.loading = true
            this.product.active = this.isActive
            if ((this.$route.params.id === 'new' && this.image) || this.image) this.getBase64(this.image, this.setImagesArray)
            else this.submit(delImg)
            this.removeItem()
            this.removeItemDep()
            this.loading = false
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${this.$i18n.t('toast.title.new_product')}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `${this.$i18n.t('toast.text.asdf')}`,
              },
            })
            this.loading = false
          }
        }
      })
    },
    async submit(delImg = false) {
      try {
        if (this.$route.params.id !== 'new') this.product.productId = this.product.id
        else this.product.productId = null
        if (this.product.active) this.product.active = 1
        else this.product.active = 0

        dbProduct
          .set(this.product)
          .then(res => {
            if (this.dependencies != null) {
              this.dependencies.forEach(async dep => {
                const obj = {
                  dependencyId: dep.id || null,
                  units: dep.units,
                  fatherProductId: res.data.id,
                  childProductId: dep.child_product_id,
                }
                await dbProduct.setDependency(obj)
              })
            }

            if (this.stocks != null) {
              this.stocks.forEach(async stock => {
                const mech = this.mechanics.find(mec => mec.id === stock.owner_id)
                  ?.name || ''
                const obj = {
                  id: stock.id || null,
                  product: stock.id ? stock.products_id : res.data.id,
                  units: stock.units,
                  mechanic: mech,
                }
                await dbStock.update(obj)
              })
            }
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${this.$i18n.t('toast.title.new_product')}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `${this.$i18n.t('toast.text.error')}`,
              },
            })
          })

        if (!delImg) this.$router.push('/products/products')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.title.product_updated')}`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `${this.$i18n.t('toast.text.update')}`,
          },
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.$i18n.t('toast.title.new_product')}`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${this.$i18n.t('toast.text.error')}`,
          },
        })
      }
    },
    getBase64(file, setArray) {
      const reader = new FileReader()
      // eslint-disable-next-line func-names
      reader.onloadend = function () {
        setArray(reader.result)
      }
      reader.readAsDataURL(file)
    },
    setImagesArray(base64String) {
      const file = this.image
      this.product.images = []
      const obj = {
        name: file.name,
        image: base64String,
      }
      this.product.images.push(obj)

      this.submit()
    },
    b64toFile(obj) {
      const byteString = atob(obj.image.split(',')[1])
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      const blob = new Blob([ab], { type: 'image/jpeg' })
      return new File([blob], obj.name)
    },
    setDependenciesName() {
      this.$refs.tabDependency.forceUpdateItems()
    },
    async removeImage() {
      await dbProduct.deleteImageProduct(this.$route.params.id)
      this.image = null
      this.save(true)
    },
  },
}
</script>
